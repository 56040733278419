<template>
  <Wrapper>
    <div class="about">
      <!-- banner -->
      <DetailBanner title="关于集团介绍"></DetailBanner>
      <!-- 集团介绍 -->
      <div class="intro">
        <div class="w">
          <div class="img col-6">
            <img :src="introductionUrl" />
          </div>
          <div class="words col-6">
            <h2>集团介绍</h2>
            <div>
              <p>{{ description }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 集团荣誉 -->
      <div class="honor" id="honor">
        <WaterTitle Chinese="集团荣誉" English="GROUP HONOR"></WaterTitle>
        <div class="w photos">
          <el-carousel :interval="4000" type="card" height="426px">
            <el-carousel-item v-for="item in honorImgs" :key="item" :autoplay="false">
              <img class="carousel-img" :src="item" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import WaterTitle from '@/components/WaterTitle.vue'
import DetailBanner from './sections/DetailBanner.vue'
import Wrapper from '../components/Wrapper.vue'

export default {
  mixins: [],
  components: {
    Wrapper,
    WaterTitle,
    DetailBanner
  },
  data() {
    return {
      description: '',
      introductionUrl: '',
      honorImgs: []
    }
  },
  created() {
    this.scrollTop()
    this.getMsg()
  },
  mounted() {},
  methods: {
    scrollTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      console.log(scrollTop)
      if (scrollTop === 0) {
        return false
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    async getMsg() {
      const res = await this.$axios.getGroupIntroductionPage({
        offset: 1,
        limit: 1,
        statusCd: 0
      })
      this.description = res.records[0].description
      this.introductionUrl = res.records[0].introductionUrl
      this.honorImgs = res.records[0].honorUrl.split(',')
    }
  }
}
</script>

<style lang="scss" scoped>
.photos {
  margin-top: 50px;
  .el-carousel__item {
    text-align: center;
  }
}

// 集团介绍
.intro {
  background-color: #fafdff;
  padding-bottom: 50px;
  .words {
    padding-right: 100px;
    p {
      color: #333;
      line-height: 2;
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-bottom: 20px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 32px;
      height: 4px;
      background-color: #0054e4;
    }
  }
  .w {
    display: flex;
    align-items: center;
    .col-6 {
      width: 50%;
    }
  }
  .img {
    position: relative;
    width: 519px;
    height: 505px;
    img {
      margin-top: 50px;
      width: 519px;  
      height: auto;  
      max-width: 100%;  
      max-height: 100%;   
    }
  }
}
.honor {
  padding: 50px 0;
  .carousel-img {
    width: 303px;
    height: auto;
  }
}
</style>
